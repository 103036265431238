import Wizard from 'common/components/wizard/Wizard'
import process from 'common/utils/process'
import AuthenticateStepup from './AuthenticateStepup'
import TwoStepMethod from './TwoStepMethod'
import { findIndex } from 'lodash'
import PasswordStep from '../signin-v2/PasswordStep'
import TwoStepCode from '../signin-v2/TwoStepCode'
import LoggedIn from '../signin-v2/LoggedIn'

export const twoStepTypes = {
  PHONE: 'MOBILE',
  EMAIL: 'EMAIL',
  PASSWORD: 'PWD',
}

export function twoStepMethodToType(method) {
  if (method === 'mobile') return twoStepTypes.PHONE
  if (method === 'pwd') return twoStepTypes.PASSWORD
  if (method?.includes('@')) return twoStepTypes.EMAIL
  return twoStepTypes.PHONE
}

async function choose2FAMethod({ processId }, { stepUpOptionId }) {
  const data = { processId, parameters: { stepUpOptionId } }
  return process.step(data, true)
}

async function send2FACode({ processId }, { code, pkat }) {
  const data = { processId, parameters: { code, pkat } }
  return process.step(data, true)
}

async function addPasswordStep({ processId }, { password, captchaResponse }) {
  const data = {
    processId,
    parameters: {
      password,
      captchaResponse,
    },
  }
  const res = await process.step(data, true)
  return res
}

export const steps = {
  StepUpOptionPrompt: 'StepUpOptionPrompt',
  PasswordPrompt: 'PasswordPrompt',
  OtpPrompt: 'OtpPrompt',
  LoggedIn: 'LoggedIn',
}

export default function StepupProcess(props) {
  const pages = {
    AuthenticateStepup: 'AuthenticateStepup',
    TwoStepMethod: 'TwoStepMethod',
    PasswordPrompt: 'PasswordPrompt',
    TwoStepCode: 'TwoStepCode',
    LoggedIn: 'LoggedIn',
  }

  const pageOrder = [
    pages.AuthenticateStepup,
    pages.TwoStepMethod,
    pages.PasswordPrompt,
    pages.TwoStepCode,
    pages.LoggedIn,
  ]

  const getPageIndex = pageName =>
    findIndex(pageOrder, page => page === pageName)

  const sharedProps = {
    ...props,
    steps,
    pages,
    getPageIndex,
  }
  const pageComponents = {
    [pages.AuthenticateStepup]: (
      <AuthenticateStepup {...sharedProps} choose2FAMethod={choose2FAMethod} />
    ),
    [pages.TwoStepMethod]: (
      <TwoStepMethod
        choose2FAMethod={choose2FAMethod}
        twoStepMethodToType={twoStepMethodToType}
        twoStepTypes={twoStepTypes}
        showCancelButton={false}
        {...sharedProps}
      />
    ),
    [pages.PasswordPrompt]: (
      <PasswordStep
        addPasswordStep={addPasswordStep}
        {...sharedProps}
        showForgotPassword={false}
        showSubtitleLink={false}
        fallback
      />
    ),
    [pages.TwoStepCode]: (
      <TwoStepCode
        send2FACode={send2FACode}
        twoStepMethodToType={twoStepMethodToType}
        twoStepTypes={twoStepTypes}
        showCancel={false}
        showTrustedDevice={false}
        fallback
        {...sharedProps}
      />
    ),
    [pages.LoggedIn]: <LoggedIn />,
  }

  return <Wizard>{pageOrder.map(page => pageComponents[page])}</Wizard>
}
