import React from 'react'
import { Box, FormControlLabel, Typography } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { UserButton } from '@admin-ui-common/base-user'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import ResendCode from 'common/components/ResendCode'
import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'
import Checkbox from '../../../common/components/checkBox/CheckBox'
import TextField from '@mui/material/TextField'

export default function OtpCode({
  submitCode,
  cancel,
  pkat,
  selectedMethod,
  error,
  disabledNext,
  showCancel = true,
  showTrustedDevice = true,
}) {
  const intl = useIntl()

  const { handleSubmit, register, watch, setValue } = useForm({
    defaultValues: {
      code: '',
      pkat: pkat,
      trustedDevice: false,
    },
  })

  const currentPkat = watch('pkat')

  function onSubmit(values) {
    submitCode(values)
  }

  function gotNewPkat(newPkat) {
    setValue('pkat', newPkat)
  }

  const emailPhonelabel =
    selectedMethod.type === 'EMAIL'
      ? intl.formatMessage(
          { id: 'sign-in-two-factor-code-email-label' },
          {
            identifier: selectedMethod.value,
            'text-strong': str => <strong>{str}</strong>,
          },
        )
      : intl.formatMessage(
          { id: 'sign-in-two-factor-code-sms-label' },
          {
            identifier: selectedMethod.value,
            'text-strong': str => <strong>{str}</strong>,
          },
        )

  const bucLabel = intl.formatMessage({
    id: 'sign-in-two-factor-code-buc-label',
  })

  const label = selectedMethod?.type === 'BUC' ? bucLabel : emailPhonelabel

  const hideMarkAsTrustedDevice = hasUIFeatureFlag('hideMarkAsTrustedDevice')

  const inputPlaceholderTextKey =
    selectedMethod?.type === 'BUC'
      ? 'sign-in-two-factor-code-buc-placeholder'
      : 'common.enter-code'

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box marginBottom={3}>
        <Typography>{label}</Typography>
      </Box>
      <TextField
        inputMode="numeric"
        aria-required
        fullWidth
        autoFocus
        label={intl.formatMessage({ id: inputPlaceholderTextKey })}
        autocomplete="one-time-code"
        {...register('code')}
        error={error}
        helperText={error}
      />
      {selectedMethod?.type !== 'BUC' && (
        <Box>
          <ResendCode
            autoFocus
            tokenId=""
            pkat={currentPkat}
            onResendFinish={gotNewPkat}
          />
        </Box>
      )}
      {!hideMarkAsTrustedDevice && showTrustedDevice && (
        <Box marginTop={2}>
          <FormControlLabel
            data-testid="otpCode-markAsTrusted-control"
            {...register('trustedDevice')}
            style={{ marginLeft: '0px' }}
            control={
              <Checkbox
                color="primary"
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
              />
            }
            label={intl.formatMessage({
              id: 'sign-in-two-factor-code-remember-device',
            })}
          />
        </Box>
      )}
      <Box marginTop={3} display="flex" gap={4}>
        <UserButton type="submit" size="large" disabled={disabledNext}>
          {intl.formatMessage({ id: 'common.button.next' })}
        </UserButton>
        {showCancel && (
          <UserButton onClick={cancel} variant="outlined" size="large">
            {intl.formatMessage({ id: 'common.button.cancel' })}
          </UserButton>
        )}
      </Box>
    </Box>
  )
}
