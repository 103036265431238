import React, { useEffect, useState } from 'react'

import { Divider, Switch } from '@admin-ui-common/base-user'

import { Button } from 'common/components/button/Button'

import FlowLayout from '../../common/layouts/FlowLayout'
import Box from '@mui/material/Box'

import { Collapse, Typography } from '@mui/material'

import conf from 'conf'

import i from 'common/utils/i'

import { capitalize } from 'lodash'
import DeclineConsentDialog from './DeclineConsentDialog'

import { InlineNotification } from '@admin-ui-common/base-user'
import { getRedirectLocation } from 'common/utils/getRedirectUrl'
import useMainRedirect from 'common/components/hooks/useMainRedirect'

const SwitchedFormEntry = ({
  title,
  description,
  checked,
  disabled,
  acceptedConsents,
  setAcceptedConsents,
}) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" flexDirection="column">
        <Typography fontWeight="medium">{capitalize(title)}</Typography>
        <Box height="10px" />
        <Typography variant="caption">{description}</Typography>
      </Box>
      <Box marginRight="-28px" marginTop="-12px">
        <Switch
          checked={checked}
          disabled={disabled}
          onChange={event => {
            if (!event.target.checked) {
              setAcceptedConsents(acceptedConsents.filter(a => a !== title))
            } else {
              setAcceptedConsents([...acceptedConsents, title])
            }
          }}
        />
      </Box>
    </Box>
  )
}

const fetchConfirmAccessModel = async () => {
  const url = `${conf.oidcRoot}/oauth/confirm_access_model`
  const response = await i.get(url).then(res => res?.body)
  return response
}

const PrivacyAndConsentPage = props => {
  const [privacyModelResponse, setPrivacyModelResponse] = useState()

  const [acceptedConsents, setAcceptedConsents] = useState([])

  const [declineDialogOpen, setDeclineDialogOpen] = useState(false)

  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const mainRedirect = useMainRedirect()

  useEffect(() => {
    async function fetchData() {
      const response = await fetchConfirmAccessModel()
      setPrivacyModelResponse(response)
    }
    fetchData()
  }, [])

  const supportedConsents =
    privacyModelResponse &&
    Object.entries(privacyModelResponse?.claimsForScopes)
      ?.map(([key, value]) => {
        if (value?.claimConsentInfo !== undefined) {
          return {
            consentTitle: key,
            consentDescription: value.claimConsentInfo?.purpose,
            mandatory:
              value.claimConsentInfo?.mandatory === 'true' ||
              value.claimConsentInfo?.mandatory === true
                ? true
                : false,
          }
        }
        return null
      })
      .filter(Boolean)

  useEffect(() => {
    const acceptedConsents = []
    supportedConsents?.forEach(({ consentTitle, mandatory }) => {
      if (mandatory) {
        acceptedConsents.push(consentTitle)
      }
    })
    setAcceptedConsents(acceptedConsents)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privacyModelResponse])

  if (!privacyModelResponse) return null

  const clientName = privacyModelResponse?.client?.clientName || 'Client'

  const ConsentComponent = ({
    consentTitle,
    consentDescription,
    mandatory = false,
    showDivider = true,
  }) => {
    const checked = mandatory ? true : acceptedConsents.includes(consentTitle)

    return (
      <>
        <SwitchedFormEntry
          title={consentTitle}
          description={consentDescription}
          checked={checked}
          disabled={mandatory}
          acceptedConsents={acceptedConsents}
          setAcceptedConsents={setAcceptedConsents}
        />
        {showDivider && (
          <Divider sx={{ marginTop: '16px', marginBottom: '16px' }} />
        )}
      </>
    )
  }

  return (
    <FlowLayout
      title={
        <>
          <Collapse in={hasError}>
            <Box mb={3}>
              <InlineNotification
                title="Error"
                message={errorMessage}
                variant="error"
                hideClose
              />
            </Box>
          </Collapse>
          Privacy Consent
        </>
      }
      subtitle={`${clientName} would like to access your following data:`}
    >
      {supportedConsents.map(
        ({ consentTitle, consentDescription, mandatory }, index) => (
          <ConsentComponent
            consentTitle={consentTitle}
            consentDescription={consentDescription}
            mandatory={mandatory}
            showDivider={index !== supportedConsents.length - 1}
          />
        ),
      )}
      <Box mt="24px">
        <Typography color="textSecondary" component="div" variant="caption">
          Remember, you can always edit your settings from the privacy dashboard
          in your self-care portal.
        </Typography>
      </Box>
      <Box mt="24px" display="flex" justifyContent="flex-end" gap="8px">
        <Button
          onClick={() => {
            setDeclineDialogOpen(true)
          }}
          variant="outlined"
        >
          Decline
        </Button>
        <Button
          onClick={() => {
            setHasError(false)
            setErrorMessage('')

            const acceptedScopes = acceptedConsents
              .map(consent => `&scope_${consent}=${consent}`)
              .join('')
            const acceptConsentUrl = `${conf.oidcRoot}/approve?remember=true${acceptedScopes}`

            i.post(acceptConsentUrl)
              .then(res => {
                if (Boolean(getRedirectLocation(res.metadata))) {
                  mainRedirect(res.metadata)
                  return
                }
              })
              .catch(res => {
                setHasError(true)
                setErrorMessage(
                  res?.body?.error_description ||
                    'An unexpected error has occured',
                )
              })
          }}
          style={{ height: '40px' }}
        >
          Accept
        </Button>
      </Box>
      <DeclineConsentDialog
        open={declineDialogOpen}
        onClose={() => {
          setDeclineDialogOpen(false)
        }}
        onDeclineCallBack={() => {
          window.location = privacyModelResponse?.redirectUri
        }}
      />
    </FlowLayout>
  )
}

export default PrivacyAndConsentPage
