import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'
import FlowLayout from 'common/layouts/FlowLayout'
import AuthenticatorCode from './TwoStepCode/AuthenticatorCode'
import OtpCode from './TwoStepCode/OtpCode'
import { Box } from '@mui/material'
import {
  getErrorCodes,
  resolveErrorEntity,
  formatErrorMessage,
} from 'common/utils/processBackendErrors'
import { TOO_MANY_RETRIES } from 'common/constants'
import maskIdentity from 'common/utils/maskIdentity'
import useMainRedirect from 'common/components/hooks/useMainRedirect'
import { getRedirectLocation } from 'common/utils/getRedirectUrl'

const selectedMethodFromState = (wizardState, twoStepMethodToType) => {
  const value = maskIdentity(
    get(wizardState, 'output.message.destination') ||
      get(wizardState, 'output.messages[0].destination'),
  )
  const type = twoStepMethodToType(value)

  return { value, type }
}

export default function TwoStepCode({
  send2FACode,
  twoStepTypes,
  twoStepMethodToType,
  wizard,
  getPageIndex,
  pages,
  steps,
  showCancel = true,
  fallback = false,
  showTrustedDevice = true,
}) {
  const intl = useIntl()
  const history = useHistory()
  const { location } = history
  const wizardState = wizard.getPageState()
  const mainRedirect = useMainRedirect()

  const selectedMethod =
    get(wizardState, 'selectedMethod') ||
    selectedMethodFromState(wizardState, twoStepMethodToType)
  const [errorMessage, setErrorMessage] = useState(null)
  const [disabledNext, setDisabledNext] = useState(false)

  function cancel() {
    window.location.reload()
  }

  async function submitCode({ code, pkat, trustedDevice }) {
    setErrorMessage(null)
    const processId =
      get(wizardState, 'twoStepOtpData.processId') ||
      get(wizardState, 'processId')

    try {
      const res = await send2FACode(
        { processId },
        { code, pkat, trustedDevice },
      )
      const stepName = get(res, 'body.stepName')
      if (stepName) {
        if (stepName === steps?.UpdatePassword) {
          history.push({
            pathname: '/password_expire',
            state: get(res, 'body'),
            search: location.search,
          })
        }

        if (stepName === steps?.AccountIdentifierPrompt) {
          const stepData = get(res, 'body')
          wizard.setPageState(stepData)
          wizard.toPage(getPageIndex(pages.EnforceAccountAssociationStep))
        }
      }

      if (get(res, 'body.userAuthenticated') === true) {
        if (Boolean(getRedirectLocation(res.metadata))) {
          mainRedirect(res.metadata)
          return
        }
        if (fallback) {
          history.push('/profile')
        }
        wizard.toPage(getPageIndex(pages.LoggedIn))
      }
    } catch (err) {
      const errorCodes = getErrorCodes(err.body)

      if (errorCodes.includes('invalid-act-consumption')) {
        setErrorMessage(formatErrorMessage({ intl, id: 'error.invalid-code' }))
      } else if (errorCodes.includes(TOO_MANY_RETRIES)) {
        setDisabledNext(true)
        setErrorMessage(
          formatErrorMessage({
            intl,
            id: 'error.process-terminated-with-too-many-retries',
          }),
        )
      } else {
        setErrorMessage(resolveErrorEntity({ intl, error: err.body }))
      }
    }
  }

  return (
    <FlowLayout
      title={intl.formatMessage({ id: 'sign-in.two-factor-code-title' })}
      subtitle={intl.formatMessage({
        id: 'sign-in.two-factor-code-subtitle',
      })}
    >
      <Box>
        {selectedMethod.type === twoStepTypes.TOTP && (
          <AuthenticatorCode
            submitCode={submitCode}
            cancel={cancel}
            error={errorMessage ?? undefined}
            disabledNext={disabledNext}
          />
        )}
        {(selectedMethod.type?.toUpperCase() === twoStepTypes.EMAIL ||
          selectedMethod.type?.toUpperCase() === twoStepTypes.PHONE ||
          selectedMethod.type?.toUpperCase() === twoStepTypes.BUC) && (
          <OtpCode
            pkat={get(wizardState, 'pkat') || get(wizardState, 'output.pkat')}
            selectedMethod={selectedMethod}
            submitCode={submitCode}
            cancel={cancel}
            error={errorMessage ?? undefined}
            disabledNext={disabledNext}
            showCancel={showCancel}
            showTrustedDevice={showTrustedDevice}
          />
        )}
      </Box>
    </FlowLayout>
  )
}
