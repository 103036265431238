import React from 'react'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material'
import { UserButton } from '@admin-ui-common/base-user'
import TextsmsIcon from '@mui/icons-material/Textsms'
import PasswordIcon from '@mui/icons-material/Password'
import EmailIcon from '@mui/icons-material/Email'
import { useIntl } from 'react-intl'
import FlowLayout from 'common/layouts/FlowLayout'
import { get } from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import maskIdentity from 'common/utils/maskIdentity'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '2.5rem',
      paddingRight: '2.5rem',
    },
  },
}))

export default function TwoStepMethod({
  twoStepTypes,
  choose2FAMethod,
  wizard,
  getPageIndex,
  pages,
  data,
  steps,
  showCancelButton = true,
}) {
  const intl = useIntl()
  const theme = useTheme()
  const wizardState = wizard?.getPageState()
  const stepData = wizardState || data

  const methodTypeIcon = {
    [twoStepTypes.EMAIL]: EmailIcon,
    [twoStepTypes.PHONE]: TextsmsIcon,
    [twoStepTypes.PASSWORD]: PasswordIcon,
  }

  function cancel() {
    //window.location.reload() // Note : TBD
  }

  function methodTypeLabel(type, value) {
    switch (type?.toUpperCase()) {
      case twoStepTypes.PASSWORD:
        return intl.formatMessage({ id: 'sign-in.enter-password' })
      case twoStepTypes.EMAIL:
        return intl.formatMessage(
          { id: 'sign-in.two-factor-method-email-label' },
          { email: value },
        )
      default:
        return intl.formatMessage(
          { id: 'sign-in.two-factor-method-sms-label' },
          { phone: value },
        )
    }
  }

  const methodTypeSecondaryLabel = {
    [twoStepTypes.PHONE]: intl.formatMessage({
      id: 'sign-in.two-factor-method-sms-sublabel',
    }),
  }

  const processTwoFAMethodData = twoFAMethods =>
    twoFAMethods
      ? Object.entries(twoFAMethods).map(([id, value]) => ({
          id,
          ...value,
          value: maskIdentity(value?.value),
        }))
      : null
  const twoFAMethods = processTwoFAMethodData(
    get(stepData, 'stepData.stepUpOptions'),
  )
  const methods = twoFAMethods

  async function selectMethod(method) {
    const processId = get(stepData, 'processId')
    const stepUpOptionId = method.id

    try {
      const res = await choose2FAMethod({ processId }, { stepUpOptionId })

      const body = get(res, 'body')

      if (get(body, 'stepName') === steps.PasswordPrompt) {
        wizard.setPageState({
          ...wizardState,
          selectedMethod: method,
          twoStepOtpData: body,
          pkat: get(res, 'body.output.pkat'),
        })
        wizard.toPage(getPageIndex(pages.PasswordPrompt))
      }

      if (get(body, 'stepName') === steps.OtpPrompt) {
        wizard.setPageState({
          ...wizardState,
          selectedMethod: {
            ...method,
            type: method?.subType.toUpperCase() || method.type.toUpperCase(),
          },
          twoStepOtpData: body,
          pkat: get(res, 'body.output.pkat'),
        })
        wizard.toPage(getPageIndex(pages.TwoStepCode))
      }
    } catch (err) {
      console.error(err)
    }
  }

  const classes = useStyles()

  return (
    <FlowLayout hideXPadding>
      <Box paddingX={{ xs: 2, sm: 5 }}>
        <Typography variant="h4">
          {intl.formatMessage({ id: 'sign-in.stepup-method-title' })}
        </Typography>

        <Box marginTop={6}>
          <Typography>
            {intl.formatMessage({
              id: 'sign-in.stepup-description',
            })}
          </Typography>
        </Box>
      </Box>
      <Box marginTop={2}>
        <List>
          {methods.map(method => (
            <ListItem
              button
              key={method.id}
              onClick={() => selectMethod(method)}
              className={classes.root}
              data-testid={method.id}
            >
              <ListItemIcon>
                <Box
                  component={
                    methodTypeIcon[method?.subType?.toUpperCase()] ||
                    methodTypeIcon[method?.type?.toUpperCase()]
                  }
                  color={theme.palette.text.primary}
                />
              </ListItemIcon>
              <ListItemText
                primary={methodTypeLabel(
                  method.subType || method.type,
                  method.value,
                )}
                secondary={methodTypeSecondaryLabel[method.type]}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box marginTop={4} paddingX={{ xs: 2, sm: 5 }}>
        {showCancelButton && (
          <UserButton variant="outlined" size="large" onClick={cancel}>
            {intl.formatMessage({ id: 'common.button.cancel' })}
          </UserButton>
        )}
      </Box>
    </FlowLayout>
  )
}
